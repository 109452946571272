import React from 'react'
import Layout from 'Layout'

const Members = () => {
  return (
    <Layout>
      members area not yet completed
    </Layout>
  )
}

export default Members
