import React from "react";
import Layout from 'Layout'

const NotFound = () => {
  return (
    <Layout>
      <h1 className="my-50">Opps! This Page Does'nt exist</h1>
    </Layout>
  );
};

export default NotFound;
